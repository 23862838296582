import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query } from "firebase/firestore";
import * as React from 'react';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from 'react-responsive';
import { db } from "./firebase";
import useStore from './store';
import Title from './Title';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "90%",
  textAlign: "center"
};

const mobileStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  height:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "95%",
  textAlign: "center",
  padding:"10px"
};

const playerWrapperStyle = {
  position: "relative",
  paddingTop: "56.25%",
};

const mobilePlayerWrapperStyle = {
  position: "relative",
  paddingTop: "170%",
};

const playerStyle = {
  position: "absolute",
  top: "0",
  left: "0"
};

// Generate Order Data
function createData(id, date, subject, description, filename, filesize, downloadlink, category) {
  return { id, date, subject, description, filename, filesize, downloadlink, category }
}

const isPreviewContents = (filename) => {
  return isImage(filename) || isVideo(filename) || isPdf(filename);
}

const isImage = (filename) => {
  const array = filename.split(".");
  const target = array[array.length - 1];
  if (target.toLowerCase() === "png" || target.toLowerCase() === "jpg" || target.toLowerCase() === "gif" || target.toLowerCase() === "jpeg" || target.toLowerCase() === "webp") {
    return true;
  }
  return false;
}

const isVideo = (filename) => {
  const array = filename.split(".");
  const target = array[array.length - 1];

  if (target.toLowerCase() === "mp4" || target.toLowerCase() === "avi" || target.toLowerCase() === "mov" || target.toLowerCase() === "m4v") {
    return true;
  }
  return false;
}

const isPdf = (filename) => {
  const array = filename.split(".");
  const target = array[array.length - 1];

  if (target.toLowerCase() === "pdf") {
    return true;
  }
  return false;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
  },
}));

const StyledSmallTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 10,
  },
}));


export default function Orders() {
  const [rows, setRows] = React.useState([]);
  const [clickedShowMore, setClickedShowMore] = React.useState(false);
  const [snapshotLength, setSnapshotLength] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [clickedFilename, setClickedFilename] = React.useState({type:"image", file:"", width:"", height:""});
  const [imgSize, setImgSize] = React.useState(null);
  const { password } = useStore();
  const [categorys, setCategorys] = React.useState([]);

  //setTimeout 내에서 useState 를 정상적으로 받아오지 못하는 경우를 대비한 useRef
  const [currentCategory, setCurrentCategory] = React.useState('All');
  const currentCategoryRef = React.useRef(currentCategory);
  currentCategoryRef.current = currentCategory;

  const handleChange = (event) => {
    setCurrentCategory(event.target.value);
    console.log("set Current Category : ", event.target.value);
    showAllData(true, event.target.value);
  };

  React.useEffect(() => {
    const q = query(collection(db, "data"), orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // const newRow = [];
      // querySnapshot.forEach((doc) => {
      //   newRow.push(createData(doc.id, doc.data().date, doc.data().subject, doc.data().description, doc.data().filename, doc.data().filesize, doc.data().downloadlink));
      //   setRows(newRow);
      // });
      // console.log("subscribe id: ", doc.id);

      console.log("update firedatabase current category : " + currentCategory);
      showAllData(!clickedShowMore);
    });

    return unsubscribe;
  }, []);

  const handleOpen = (filename) => {
    if (isImage(filename)) {
      handlePreview(filename);
    } else if (isVideo(filename)) {
      handlePreviewVideo(filename);
    } else if (isPdf(filename)) {
      handlePreviewPdf(filename);
    }
  };
  const handlePreview = (filename) => {
    console.log("handlePreview click filename : ", filename);
    const image = new Image();
    const url = "http://bonkab.com:8989/download/" + filename; //read 보다 download 가 더 빠른듯

    image.onload = () => {
      console.log("handlePreview image onload... width " + image.width);
      setClickedFilename({type:"image", file:url, width:image.width, height:image.height});

      const verticalImage = /*isDesktopOrLaptop &&*/ (image.height > image.width);
      const mobileVerticalImage = verticalImage && !isDesktopOrLaptop;

      const defaultWidth = verticalImage ? "auto":"100%";
      const defaultHeight = mobileVerticalImage ? window.innerHeight * 0.60 : verticalImage ? window.innerHeight * 0.75 :"auto";

      console.log("handlePreview width : " + defaultWidth + "height" + defaultHeight);
      setImgSize({width:defaultWidth, height:defaultHeight});

      setOpen(true);
    };
    console.log("handlePreview 111...");
    image.src = url;
    console.log("handlePreview 222...");
  }

  const handlePreviewVideo = (filename) => {
    console.log("handlePreviewVideo click");
    setClickedFilename({type:"video", file:"http://bonkab.com:8989/video/" + filename});
    setOpen(true);
  };

  const handlePreviewPdf = async (filename) => {
    console.log("handlePreviewPdf click");

    //새창으로 표시함.
    window.open("http://bonkab.com:8989/read/" + filename, "_blank");
  };

  const [deleteProgressOpen, setDeleteProgressOpen] = React.useState(false);
  const handleDeleteProgressClose = () => {
    setDeleteProgressOpen(false);
  };
  const handleDeleteProgressToggle = () => {
    setDeleteProgressOpen(!open);
  };

  const handleClose = () => setOpen(false);
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  React.useEffect(() => {
    setTimeout(async () => {
      const snapShot = await getDocs(query(collection(db, "data"), orderBy("timestamp", "desc")));
      console.log("snapshot's length : " + snapShot.size);
      setSnapshotLength(snapShot.size); //전체 스냅샷의 크기를 저장하고 있음.
      let newRow = [];
      let newCategory = [];
      snapShot.forEach((doc) => {

        //forEach 에서는 이상한 것이지만 귀찮으니 그냥 하는걸로
        // if (newRow.length >= 5) {
        //   console.log("length is 5 return...");
        //   return false;
        // }

        // console.log(`${doc.id} => ${doc.data()}`);
        newRow.push(createData(doc.id, doc.data().date, doc.data().subject, doc.data().description, doc.data().filename, doc.data().filesize, doc.data().downloadlink, doc.data().category));
        setRows(newRow);

        if (doc.data().category !== ""  && doc.data().category !== undefined) {
          newCategory.push(doc.data().category);
        }

        return true;
      });
      const set = new Set(newCategory);
      const uniquenewCategory = [...set];
      setCategorys(uniquenewCategory);
      setCategorys(newCategory);
    }, 10);

  }, []);


  const onDownload = (row) => {
    if (row.filename === null || row.filename === "") {
      alert("다운로드 할 수 있는 파일이 없습니다");
      return;
    }
    const link = document.createElement("a");
    link.download = row.filename;
    link.href = "http://bonkab.com:8989/download/" + row.filename;
    link.click();
  };

  const isDeleteModeEnabled = () => {
    return password === "fuck";
  }

  const onDelete = (row) => {
    console.log("onDelete");

    if (password !== "fuck") {
      alert("비밀번호가 틀렸습니다");
      return;
    }

    handleDeleteProgressToggle();
    if (row.filename === null || row.filename === "") {
      //firestore 에서만 지움
      deleteFireStore(row.id);
      return;
    }

    axios.delete("http://bonkab.com:8989/delete/" + row.filename)
    .then((res) => {
      console.log("delete sucesss res ", res);
      deleteFireStore(row.id);
    }).catch((err) => {
      console.log("delete fail : " + err);
    });
  };

  const deleteFireStore = (id) => {
    console.log("delete Firestore id :" + id);
    const myDoc = doc(db, "data", id);
    console.log("delete Firestore doc => ", myDoc);
    deleteDoc(myDoc).then(() => {
      console.log("fireStore data delete...");
      handleDeleteProgressClose();
      showAllData(true);
    });
  };

  const showAllData = (showMoreNonClicked = false, category) => {
    console.log("showAllData");
    setTimeout(async () => {
      const snapShot = await getDocs(query(collection(db, "data"), orderBy("timestamp", "desc")));
      console.log("showAllData snapShot.size " + snapShot.size);
      setSnapshotLength(snapShot.size); //전체 스냅샷의 크기를 저장하고 있음.
      let newRow = [];
      let newCategory = [];
      snapShot.forEach((doc) => {

        //forEach 에서는 이상한 것이지만 귀찮으니 그냥 하는걸로
        if (showMoreNonClicked === true && newRow.length >= 5) {
          // console.log("length is 5 return...");
          //return false;
        }
        // console.log(`${doc.id} => ${doc.data()}`);

        console.log("showAllData current category1 : ", currentCategoryRef.current);
        console.log("showAllData current category2: ", currentCategory);
        console.log("showAllData category parameter: ", category);
        if (category === undefined) {
          category = currentCategory;
        }

        //category 는 인자로 전달된 category 임.
        // console.log("showAllData current category ", category);
        if (category !== "All" && category !== "") {
          if (doc.data().category === category) {
            newRow.push(createData(doc.id, doc.data().date, doc.data().subject, doc.data().description, doc.data().filename, doc.data().filesize, doc.data().downloadlink, doc.data().category));
          }
        } else {
          newRow.push(createData(doc.id, doc.data().date, doc.data().subject, doc.data().description, doc.data().filename, doc.data().filesize, doc.data().downloadlink, doc.data().category));
        }

        setRows(newRow);

        if (doc.data().category !== "" && doc.data().category !== undefined) {
          newCategory.push(doc.data().category);
        }

      });
      const set = new Set(newCategory);
      const uniquenewCategory = [...set];
      setCategorys(uniquenewCategory);

      if (snapShot.size === 0) {
        setRows(newRow);
      }
    }, 10);
  };

  function preventDefault(event) {
    event.preventDefault();
    setClickedShowMore(true);
    showAllData();
  }


  const handleClickImg = () => {
    if (imgSize === null) {
      console.log("handleClickImg imgSize is null");
      return;
    }

    const verticalImage = /*isDesktopOrLaptop &&*/ (clickedFilename.height > clickedFilename.width);
    const mobileVerticalImage = verticalImage && !isDesktopOrLaptop;
    if (verticalImage === true) {
      console.log("handleClickImg verticalImage is true");
      let newWidth = imgSize.width;
      let newHeight = imgSize.height;

      if (newWidth === "auto") {
        newWidth = "100%";
      } else {
        newWidth = "auto";
      }

      if (newHeight === "auto") {
        if (mobileVerticalImage) {
          newHeight = window.innerHeight * 0.60;
        } else {
          newHeight = window.innerHeight * 0.75;
        }
      } else {
        newHeight = "auto"
      }
      setImgSize({width:newWidth, height:newHeight});
    }
  };

  const PreviewModal = () => {
    console.log("Preview modal create width ", imgSize?.width);
    const verticalImage = /*isDesktopOrLaptop &&*/ (clickedFilename.height > clickedFilename.width);
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={isDesktopOrLaptop ? style : mobileStyle}>
          {
            verticalImage ?
            <Typography id="modal-modal-title" variant="h6" component="h2">
              사진 클릭시 커집니다.
            </Typography>
            :
            <></>
          }
          <div style={{textAlign:"right"}}>
            <CloseIcon onClick={() => setOpen(false)}/>
          </div>
            {
              clickedFilename.type === "image" ?
              <div style={{paddingTop:"10px"}}>
                <img src={clickedFilename.file} width={imgSize?.width} height={imgSize?.height} alt='preview img' onClick={() => handleClickImg()}/>
              </div>
              :
              //X버튼 때문에 그러함.
              <div style={{paddingTop:"10px"}}>
                <div style={isDesktopOrLaptop ? playerWrapperStyle : mobilePlayerWrapperStyle}>
                <ReactPlayer
                  className="react-player"
                  style={playerStyle}
                  url={clickedFilename.file}
                  width="100%"
                  height="100%"
                  muted={true} //chrome정책으로 인해 자동 재생을 위해 mute 옵션을 true로 해주었다.
                  playing={true}
                  controls={true}
                  loop={true} />
                </div>
              </div>
            }
          </Box>
        </Fade>
      </Modal>
    )
  };

  const DeleteProgress = () => {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={deleteProgressOpen}
        onClick={handleDeleteProgressClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  };

  const PreviewContentsIcon = ( {filename}) => {
    if (isVideo(filename)) {
      return (<YouTubeIcon color='success'/>);
    } else if (isImage(filename)) {
      return (<ImageIcon color='info'/>);
    } else if (isPdf(filename)) {
      return (<PictureAsPdfIcon color='primary'/>);
    }
  }

  return (
    isDesktopOrLaptop ?
    <React.Fragment>
      <div style={{display:"inline"}}>
        <FormControl sx={{ m: 0.5, minWidth: 150}}  size="small" >
          <InputLabel id="demo-simple-select-autowidth-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={currentCategory}
            onChange={handleChange}
            autoWidth
            label="Category"
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>

            {
              categorys?.map((c) => (
              <MenuItem value={c}>{c}</MenuItem>
            ))
            }


          </Select>
        </FormControl>
      </div>

      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell width="10%">Date</StyledTableCell>
            <StyledTableCell width="15%">Subject</StyledTableCell>
            <StyledTableCell width="20%">Description</StyledTableCell>
            <StyledTableCell width="35%">Filename</StyledTableCell>
            <StyledTableCell width="10%">Filesize</StyledTableCell>
            <StyledTableCell align="right">Download</StyledTableCell>
            {
              isDeleteModeEnabled() === true ?
              <StyledTableCell align="center">Delete</StyledTableCell>
              :
              <></>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length === 0 ?
            <TableRow key={0}>
              <TableCell width="100%">
                <Typography component="div" variant="h6" color="black" gutterBottom>
                  자료가 없습니다.
                </Typography>
              </TableCell>
            </TableRow>
            :
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ flexGrow: 1, minWidth: 100, maxWidth: 150 }}>{row.date}</TableCell>
                <TableCell sx={{ flexGrow: 2, minWidth: 100, maxWidth: 200 }}>{row.subject}</TableCell>
                <TableCell sx={{ flexGrow: 3, minWidth: 150, maxWidth: 300 }}>{row.description}</TableCell>
                <TableCell sx={{ flexGrow: 4, minWidth: 200, maxWidth: 400 }} onClick={() => handleOpen(row.filename)}>
                  <Box display="flex" alignItems="center">
                    {isPreviewContents(row.filename) && <PreviewContentsIcon filename={row.filename} />}
                    <span style={{ marginLeft: '8px' }}>{row.filename}</span>
                  </Box>
                </TableCell>
                <TableCell sx={{ flexGrow: 1, minWidth: 100, maxWidth: 150 }}>{row.filesize}</TableCell>
                <TableCell sx={{ flexGrow: 1, minWidth: 50, maxWidth: 100 }} align="right">
                  <Button variant="contained" disabled={row.filename === "" ? true : false} onClick={() => onDownload(row)}>
                    <DownloadIcon />
                  </Button>
                </TableCell>
                {
                  isDeleteModeEnabled() === true ?
                  <TableCell sx={{ flexGrow: 1, minWidth: 50, maxWidth: 100 }} align="center">
                    <Button variant="contained" onClick={() => onDelete(row)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                  : <></>
                }
              </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* {
        !clickedShowMore && snapshotLength > 5 ?
        <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
          See more datas...
        </Link>
        : <div></div>
      } */}
      <PreviewModal/>
      <DeleteProgress/>
    </React.Fragment>
    :
    // 모바일페이지입니다.
    <React.Fragment>
      <Title>Archives</Title>
      <Table size="small" sx={{ width: 1 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell size='small' width="10%">Subject</StyledTableCell>
            <StyledTableCell size='small' width="60%">Filename</StyledTableCell>
            <StyledTableCell size='small' width="10%" align='center'>{isDeleteModeEnabled() === true ? "D" : "File"}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length === 0 ?
              <TableRow key={0}>
                <TableCell width="100%">
                  <Typography component="div" variant="subtitle2" color="black" gutterBottom>
                    자료가 없습니다.
                  </Typography>
                </TableCell>
              </TableRow>
            :
            rows.map((row) => (
            <TableRow key={row.id}>
              <StyledSmallTableCell size='small'>{row.subject}</StyledSmallTableCell>
              <StyledSmallTableCell size='small' onClick={() => handleOpen(row.filename)}>
                {row.filename}
              </StyledSmallTableCell>

              {
                isDeleteModeEnabled() === true ?
                <StyledSmallTableCell align="right" size='small'>
                  {
                    row.filename !== "" ?
                    <DeleteIcon onClick={() => onDelete(row)}/>
                    :
                    <DoDisturbIcon/>
                  }
                </StyledSmallTableCell>
                :
                <StyledSmallTableCell align="right" size='small'>
                {
                  row.filename !== "" ?
                  <DownloadIcon onClick={() => onDownload(row)}/>
                  :
                  <DoDisturbIcon/>
                }
              </StyledSmallTableCell>
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* {
        !clickedShowMore && snapshotLength > 5 ?
        <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 1, fontSize: 10 }}>
          See more datas...
        </Link>
        :
        <div></div>
      } */}
      <PreviewModal/>
    </React.Fragment>
  );
}
